<template>
  <v-theme-provider dark>
    <section
      id="resources"
      class="accent text-center"
      space="56"
    >
      <v-img
        :src="require('@/assets/background/4.png')"
        :max-height="maxHeight"
        gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
      >
        <!-- <base-icon class="mb-8">
          mdi-twitter
        </base-icon> -->

        <base-section-heading
          title="Resources"
          class="transparent mt-12"
        >
          <!-- Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi aspernatur recusandae aut repudiandae illo error obcaecati dolores voluptate, tempore. -->
        </base-section-heading>

        <v-responsive
          class="mx-auto mb-12"
          max-width="1350"
        >
          <v-container>
            <v-row
              align="center"
              justify="center"
            >
              <v-col
                cols="12"
                md="4"
              >
                <v-carousel
                  show-arrows-on-hover
                  cycle
                  interval="5000"
                  progress
                  dark
                  height="250"
                >
                  <v-carousel-item
                    v-for="(item,i) in ytbthumbnails"
                    :key="i"
                    :src="require(`@/assets/youtube/${item.img}`)"
                    reverse-transition="scroll-x-transition"
                    transition="scroll-x-transition"
                    :href="item.to"
                    target="_blank"
                  />
                </v-carousel>
              </v-col>

              <v-col
                v-for="(item, i) in linkitems"
                :key="i"
                cols="12"
                md="4"
              >
                <a
                  :href="item.link"
                  target="_blank"
                >
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-icon
                      size="200"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-row>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <span
                      class="text-h5 font-weight-bold upper-case"
                    >
                      {{ item.title }}
                    </span>
                  </v-row>
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-responsive>
      </v-img>

      <!-- <a
        class="d-inline-block mb-8"
        href="https://vuetifyjs.com"
        style="text-decoration: none;"
        target="_blank"
      >
        https://vuetifyjs.com
      </a>

      <div class="py-4" />

      <v-btn
        class="font-weight-bold"
        color="white"
        href="https://twitter.com/vuetifyjs"
        light
        min-width="168"
        target="_blank"
        x-large
      >
        Follow Us

        <v-icon right>
          mdi-open-in-new
        </v-icon>
      </v-btn> -->
    </section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'Resources',

    provide: {
      heading: { align: 'center' },
    },
    data: () => ({
      ytbitems: [
        {
          src: '<iframe width="350" height="250" src="https://www.youtube.com/embed/oxk0x44KezI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        },
        {
          src: '<iframe width="350" height="250" src="https://www.youtube.com/embed/Izt5z9C7Vd8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        },
        {
          src: '<iframe width="350" height="250" src="https://www.youtube.com/embed/lptIx54tkGE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        },
      ],
      ytbthumbnails: [
        {
          img: 'bernese.jpg',
          to: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
        {
          img: 'ezsurv.jpg',
          to: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
        {
          img: 'infinity.jpg',
          to: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
        {
          img: 'magnet.jpg',
          to: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
        {
          img: 'pov.jpg',
          to: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
        {
          img: 'tbc.jpg',
          to: 'https://www.youtube.com/channel/UCtJd_jQSLOy8MxwE_7dh2eA',
        },
      ],
      linkitems: [
        {
          icon: 'mdi-github',
          link: 'https://github.com/sfull/p3Bernese_Public',
          title: 'Bernese',
        },
        {
          icon: 'mdi-database-export-outline',
          link: 'https://dynaconvert.positionplusplus.com/',
          title: 'DynaConvert',
        },
      ],
    }),

    computed: {
      maxHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? '450' : '950'
      },
    },
  }
</script>

<style scoped>
  #app iframe {
    width: 100%;
    height: 250px;
  }

  a:link {
    text-decoration: none;
    color: white;
  }

  a:visited {
    text-decoration: none;
    color: white;
  }
</style>
